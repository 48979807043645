@import "./colours.scss";

.home-hero {
    &__container {
        background-color: $violet;
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid $purple;
    }
    
    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;
        margin-bottom: 3rem;

        @media only screen and (max-width: 768px) {
            padding-top: 8rem;
            margin-bottom: 8rem;
        }

        img {
            max-width: 50%;
            @media only screen and (max-width: 768px) {
                max-width: 80%;
            }
        }
    }
    &__waves {
        width: 100%;
    }
}


.wave {
    &--violet {
        fill: $violet;
        opacity: 0.6;
    }
    &--blue {
        fill: $blue;
        opacity: 0.5;
    }
    &--green {
        fill: $green;
        opacity: 0.6;
    }
    &--purple {
        fill: $purple;
        opacity: 0.6;
    }
    &--pink {
        fill: $pink;
        opacity: 0.6;
    }
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/* /* Shrinking for mobile */
@media (max-width: 768px) {
    .home-hero__waves {
        height: 40px;
        min-height: 40px;
    }
}