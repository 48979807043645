@import "../../node_modules/normalize.css";
@import "../../node_modules/tachyons-sass";
@import "./colours.scss";
@import "./loader";
@import "./navbar";
@import "./latest-project";
@import "./home-hero";
@import "./button";
@import "./footer";

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-size: 16px;
    background-color: $very-light-grey;
}

.page-content {
    margin-top: 70px;
}

h1, p, div {
    font-family: "Raleway", sans-serif;
}