@import "./colours.scss";

.latest-project {
    &__container {
        padding: .5rem 1rem;
        width: 100%;
        margin-top: 1rem;
        // min-height: 600px;
        background-color: $very-light-grey;

        @media only screen and (max-width: 768px) {
            padding: .5rem .5rem;
        }
    }
    &__heading {
        margin: 2rem auto 1.5rem;
        font-size: 2rem;
        font-weight: 700;
        // text-align: center;
        max-width: 1600px;
        @media only screen and (max-width: 768px) {
            margin-top: 1rem;
        }
    }
    &__inner {
        max-width: 1600px;
        margin: auto;
        display: grid;
        grid-template-columns: 3fr 2fr;

        @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }
    &__video-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
        padding-right: 2rem;
        @media only screen and (max-width: 768px) {
            margin: 0;
            padding: 0;
        }
    }
    &__details {
        padding-left: 2rem;
        height: 100%;

        @media only screen and (max-width: 768px) {
            margin: 0 .5rem;
            padding: 1.5rem 0;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
    &__details {
        font-weight: 400;
        font-size: 1.25rem;
    }
    &__description { 
        margin-bottom: 2rem;
    }
}