@import "./colours";

.button {
    text-decoration: none;
    border: none;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.6;
    padding: .5rem 1rem;
    cursor: pointer;
    border-radius: .25rem;
    transition: transform 100ms ease-in;
    text-align: center;
    width: auto;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    &:hover {
        transform: scale(1.01);
    }

    &--large {
        font-size: 1.5rem;
    }

    &--purple {
        background-color: $purple;
        color: $white;
        &:hover {
            background-color: lighten($purple, 3%);
        }
    }
    &--pink {
        background-color: $pink;
        color: $white;
        &:hover {
            background-color: lighten($pink, 3%);
        }
    }
    &--green {
        background-color: $green;
        color: $white;
        &:hover {
            background-color: lighten($green, 3%);
        }
    }
    &--blue {
        background-color: $blue;
        color: $jet;
        &:hover {
            background-color: darken($blue, 3%);
        }
    }
    &--white {
        background-color: $white;
        color: $jet;
        &:hover {
            background-color: darken($white, 3%);
        }
    }
    &--black {
        background-color: $jet;
        color: $white;
        &:hover {
            background-color: lighten($jet, 3%);
        }
    }
}
