$jet: #41393E;
$violet: #5D4E6D;
$green: #00AF54;
$white: #ffffff;
$very-light-grey: #eeeeee; 
$light-grey: #dddddd; 
$grey: #cccccc; 
$dark-grey: #bbbbbb; 
$very-dark-grey: #aaaaaa; 
$blue: #22FFFC;
$pink: #F756FF;
$purple: #866EFF;