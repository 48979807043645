@import "./colours.scss";


$navHeight: 70px;

.navbar {
    &__container {
        width: 100%;
        height: $navHeight;
        background-color: $jet;
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        
        color: $white;
        box-shadow: 0px 2px 1px 1px $purple;
        
    }
    
    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        max-width: 1600px;

        @media only screen and (max-width: 768px) {
            padding: 0 .5rem;
        }
    }

    &__left {
        justify-self: flex-start;
        display: flex;
        align-items: center;
        height: 100%;
    }
    
    &__right {
        justify-self: flex-end;
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__logo {
        max-height: calc($navHeight - 8px);
        padding: .25rem 0;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
            padding: .25rem;
        }
    }

    &__hamburger {
        color: $white;
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }

    &__items {
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;

        @media only screen and (max-width: 768px) {
            margin: 0;
            position: fixed;
            height: auto;
            width: calc(100% * 2 / 3);
            background-color: $white;
            font-size: 1.5rem;
            color: $jet;
            left: 100%;
            top: $navHeight;
            transform: translateX(0%);
            transition: transform 300ms ease-in-out;
            flex-direction: column;
            border: none;
            border-bottom-left-radius: .25rem;
            display: flex;
            
            &.is-active {
                box-shadow: -2px 2px 1px $purple;
                transform: translateX(-100%);
            }
        }
    }

    &__item {
        font-size: 1.25rem;
        padding: .5rem 1rem;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: flex-end;
        color: $white;
        text-decoration: none;

        @media only screen and (max-width: 768px) {
            color: $jet;
            width: 100%;
            padding: 1rem;
        }
        
        &.is-active {
            background-color: darken($violet, 3%);

            @media only screen and (max-width: 768px) {
                color: $white;
                background-color: $violet;
            }
        }
        
        &:hover {
            background-color: $violet;
        }
    }
}