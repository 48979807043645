$primary-color: $green;
@import "./colours.scss";
@import "../../node_modules/loaders.css/src/animations/line-scale-random.scss";

@keyframes fadeBackground {
    0% {
        background-color: none;
        opacity: 0;
    }
    
    100% {
        opacity: 1;
        background-color: $violet;
    }
}

.loader__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    animation: fadeBackground 3s ease-in-out infinite alternate;
}

.loader__spinner {
    transform: scale(2);
}